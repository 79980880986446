/* eslint-disable perfectionist/sort-objects */
const screens = {
  xs: '350px',
  sm: '600px',
  md: '800px',
  ml: '1080px',
  lg: '1150px',
  xl: '1400px',
  '2xl': '1700px',
} as const;

export { screens };
